<template>
  <base-layout>
    <h1>Как принять участие</h1>
    <section class="about">
      <div class="product-carousel-1">
        <img class="number" src="../assets/img/about_1.png" alt="">
        <h2><img class="number_desktop" src="../assets/img/about_1.png" alt="">купи «Добрый» для детей</h2>
        <agile :options="productsAboutOptions">
          <div v-for="item in carousel.about" :key="item.key">
            <div style="text-align: center;">
              <img :src="getPngImgUrl(item.image.src)" :alt="item.image.alt">
              <p>{{ item.image.caption }}</p>
            </div>
          </div>
        </agile>
      </div>
      <div class="product-carousel-2">
        <img class="number" src="../assets/img/about_2.png" alt="">
        <h2><img class="number_desktop" src="../assets/img/about_2.png" alt="">зарегистрируй чек от покупки</h2>
      </div>

      <div class="product-carousel-3">
        <img class="number" src="../assets/img/about_3.png" alt="">
        <h2><img class="number_desktop" src="../assets/img/about_3.png" alt="">копи баллы</h2>
<!--        <div class="points">-->
          <agile class="points" :options="aboutOptions">
          <div class="points-item"><img src="../assets/img/about_points_1.png" alt=""></div>
          <div class="points-item"><img src="../assets/img/about_points_2.png" alt=""></div>
          <div class="points-item"><img src="../assets/img/about_points_3.png" alt=""></div>
          <div class="points-item"><img style="width: 64%" src="../assets/img/about_points_4.png" alt=""></div>
          <div class="points-item"><img src="../assets/img/about_points_5.png" alt=""></div>
          <div class="points-item"><img src="../assets/img/about_points_6.png" alt=""></div>
          </agile>
      </div>
      <div class="product-carousel-4">
        <img class="number" src="../assets/img/about_4.png" alt="">
        <h2><img class="number_desktop" src="../assets/img/about_4.png" alt="">обменивай баллы на призы</h2>
        <agile :options="aboutOptions" class="prizes">
          <div class="prizes-item"><img src="../assets/img/about_prizes_1.png" alt=""></div>
          <div class="prizes-item"><img src="../assets/img/about_prizes_3.png" alt=""></div>
          <div class="prizes-item"><img src="../assets/img/about_prizes_5.png" alt=""></div>
          <div class="prizes-item"><img src="../assets/img/about_prizes_7.png" alt=""></div>
          <div class="prizes-item third"><img src="../assets/img/about_prizes_2.png" alt=""></div>
          <div class="prizes-item third"><img src="../assets/img/about_prizes_6.png" alt=""></div>
          <div class="prizes-item third"><img src="../assets/img/about_prizes_4.png" alt=""></div>
        </agile>
      </div>
    </section>
    <img class="about-bottom" src="../assets/img/about_bottom.png" alt="">
    <div class="about-bottom-text">
      Дополнительные баллы можно набрать, регистрируя продукты
      и участуя в активностях на сайте
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import { VueAgile } from 'vue-agile'

import {
  productsAboutOptions,
  aboutOptions,
  carousel
} from '@/carousel/config'

export default {
  name: "About",
  components: {
    agile: VueAgile,
    BaseLayout
  },

  data() {

    return {
      productsAboutOptions,
      aboutOptions,
      carousel,
    }
  },

  methods: {
    getSvgImgUrl(image) {
      return require(`../assets/svg/${image}`)
    },

    getPngImgUrl(image) {
      return require(`../assets/img/${image}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

@media screen and (min-width: 993px) {
  .about {
    &::before, &::after {
      content: "";
      position: absolute;
      display: block;
      background-size: cover;
    }

    &::before {
      width: 90px;
      height: 100px;
      top: 120px;
      left: 10%;
      background-image: url("../assets/svg/label-yellow-thunderbolt.svg");
    }

    &::after {
      width: 100px;
      height: 95px;
      top: 120px;
      right: 10%;
      background-image: url("../assets/svg/label-heart.svg");
    }
  }
}

.about-bottom {
  display: block;
  margin: 0 auto;
  max-height: 70px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.about-bottom-text {
  text-align: center;
  max-width: 815px;
  color: $white;
  margin: 0 auto;
  margin-bottom: 75px;

}

a {
  text-decoration: none;
}

.wrapper {
  background-color: $green;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: $white;
}


.about {
  margin: 20px 8% 20px 8%;
  background-size: contain;
  background-position: center;
  background-color: $white;
  border-radius: 40px;
  padding: 20px 20px 37px 20px;

h2 {
    margin-top: 15px;
  }



}

.product-carousel-1 {
  background-size: contain;
  background-position: center;
  background-image: url("../assets/img/about_blue_bg.svg");
  background-repeat: no-repeat;
  min-height: 36vw;
  position: relative;
  padding: 65px 40px 0px 65px;

  h2 {
    color: $purple;
  }
}

.product-carousel-2 {
  background-size: contain;
  background-position: center;
  background-image: url("../assets/img/about_purple_bg.png");
  background-repeat: no-repeat;
  min-height: 15vw;
  position: relative;
  h2 {
    color: $white;
  }
  //padding: 120px 40px 0px 80px;
}

.product-carousel-3 {
  background-size: contain;
  background-position: center;
  background-image: url("../assets/img/about_orange_bg.png");
  background-repeat: no-repeat;
  min-height: 49vw;
  position: relative;
  padding: 23px 20px 20px 20px;
  h2 {
    color: $purple;
  }
}

.product-carousel-4 {
  background-size: contain;
  background-position: center;
  background-image: url("../assets/img/about_pink_bg.png");
  background-repeat: no-repeat;
  min-height: 66vw;
  position: relative;
  h2 {
    color: $white;
  }
  //padding: 120px 40px 0px 80px;
}

.product-carousel-1 img {
  max-height: 15vw;
}

@media screen and (max-width: 992px) {
  img.number {
    width: 40px !important;
    margin: 0 auto;
    display: block;
    transform: translateY(15px);
  }

  .points-item, .prizes-item {
    img {
      width: 65%;
      display:block;
      margin: 0 auto;
    }
  }

  .product-carousel-1 img {
    max-height: none;
    width: 40%;
  }

  .about {
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .product-carousel-1 {
    background-image: url("../assets/img/mobile_about_blue_bg.png");
    width: 100%;
    height: auto;
    min-height: 153vw;
    padding: 0;
    position: relative;
    background-size: contain;
    //padding-top: 50px;
   //top: -40px;
    padding-top: 25px;
    h2 {
      //padding-top: 41px;
    }
  }

  .about {
    position: relative;
  }

  .about:before {
    content: '';
    position: absolute;
    left:0;
    top:-2px;
    height: 100px;
    width: 100%;
    background-color: $green;
  }

  .product-carousel-2 {
    background-image: url("../assets/img/mobile_about_purple.png");
    width: 100%;
    height: auto;
    padding: 0;
    min-height: 46vw;
    background-size: contain;
    //top: -64px;
    //padding-top: 40px;
    h2 {
      //padding-top: 41px;
    }
  }

  .product-carousel-3 {
    background-image: url("../assets/img/mobile_about_orange_bg.png");
    width: 100%;
    height: auto;
    min-height: 120vw;
    padding: 0;
    background-size: contain;
    padding-top: 20px;
    //top: -100px;
    //padding-top: 73px;
  }

  .product-carousel-4 {
    background-image: url("../assets/img/mobile_about_pink_bg.png");
    width: 100%;
    height: auto;
    min-height: 150vw;
    padding: 0;
    background-size: contain;
    top: -18px;
    //padding-top: 30px;
    //top: -174px;
    //padding-top: 70px;

    .number, h2 {
      transform: translateY(30px);
    }
  }

  .prizes {
    //padding-top: 75px;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 35px;
  }

  .prizes-item {
    text-align: center;
    img {
      width: 60%;
    }
  }

  .number_desktop {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .product-carousel-2 h2 {
    color: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-80%);
  }
  .product-carousel-4 h2 {
    transform: translateY(26px);
  }

  .points-item {
    width: 33% !important;
    text-align: center;
    display: inline-block;
    margin-top: 40px;
    img {
      width: 75%;
      vertical-align:top; /* <---- this */
      display: inline-block;
    }
  }

  .number_desktop {
    width: 40px;
    display: inline-block;
    margin: 0 10px -10px 15px;
  }

  .prizes-item {
    width: 25% !important;
    text-align: center;
    display: inline-block;
    margin-top: 40px;
    img {
      width: 75%;
      vertical-align:top; /* <---- this */
      display: inline-block;
    }
  }

  .prizes-item.third {
    width: 33% !important;
    margin-top: 60px;
    img {
      width: 60%;
    }
  }
}

@media screen and (max-width: 992px) {

}

</style>

<style>
.agile__slide {
  position: relative;
}
</style>

<style>
.agile__nav-button:hover {
  cursor: pointer;
}

.agile__dots {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.what-to-do .agile__dots {
  bottom: -160px;
}

.products .agile__dots {
  bottom: -200px;
}

.prizes .agile__dots {
  bottom: -139px
}


.agile__dots {
  bottom: -250px;
}

.agile__dot {
  display: block;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 10px;
  transition: width 200ms;
  margin: 0 2.5px;
}

.agile__dot button {
  opacity: 0;
  height: 100%;
  width: 100%;
  color:transparent;
}

.agile__dot--current {
  width: 35px;
}

.descriptor-carousel .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.descriptor-carousel .agile__nav-button:after {
  content: url('../assets/svg/arrow-white.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.descriptor-carousel .agile__nav-button--next {
  transform: rotate(180deg);
}

.descriptor-carousel .agile__actions {
  justify-content: center;
}

.agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.agile__nav-button:after {
  content: url('../assets/svg/arrow-white.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.agile__nav-button--next {
  transform: rotate(180deg);
}

.agile__actions {
  justify-content: space-between;
  position: absolute;
  width: 104%;
  bottom: 159px;
  left: -2.5%;
}

@media screen and (max-width: 992px) {

  .agile__actions {
    bottom: 250px;
    width: 95%;
    left: 2.5%;
  }

  .agile__dots {
    bottom: -300px;
  }

}

.products .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.products .agile__nav-button:after {
  content: url('../assets/svg/arrow-white.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.products .agile__nav-button--next {
  transform: rotate(180deg);
}

.products .agile__actions {
  justify-content: space-around;
  position: absolute;
  width: 100%;
  bottom: 175px;
}

@media screen and (max-width: 992px) {
  .about-bottom {
    display: none !important;
  }

  .about-bottom-text {
    display: none !important;

  }

  .descriptor-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .what-to-do .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .what-to-do .agile__nav-button:after {
    content: url('../assets/svg/arrow-white.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .what-to-do .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .what-to-do .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .merch .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .merch .agile__nav-button:after {
    content: url('../assets/svg/arrow-white.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .merch .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .merch .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .certificates-carousel .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .certificates-carousel .agile__nav-button:after {
    content: url('../assets/svg/arrow-white.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .certificates-carousel .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .certificates-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .prizes-carousel .agile__actions {
    bottom: 50%;
  }

  .products .agile__actions {
    justify-content: space-between;
  }

  .product-carousel-4 .agile__dots {
    bottom: -256px;
  }
}

.number {
  display: none;
}

.timer-block {
  color: #fff !important;
  width: 125px;
  height: 125px;
  padding-top: 25px;
  margin: 0 5px 0 5px;
  display: inline-block;
  border: 4px solid #3F3F3F;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  font-family: "Marvin Round" !important;
}

.timer-number {
  font-size: 26px !important;
  display: block;
}

.timer {
  margin: 15px;
  text-align: center;
}

.timer h2 {
  color:#fff
}

</style>